<template>
  <v-card :loading="isBusy">
    <v-card-title class="headline">
      Claim
      <span v-if="this.promotion"
        >-{{ this.promotion.name }} (<a @click="showUploadedFilesDialog()">{{ this.promotion.promotionKey }}</a
        >)</span
      >
    </v-card-title>

    <v-card-text>
      <v-dialog v-model="uploadedFilesDialog" max-width="800" persistent>
        <v-card>
          <v-card-title>Files</v-card-title>
          <v-card-text>
            <v-container class="ma-0 pa-0">
              <v-row dense>
                <v-col cols="1" dense></v-col>
                <v-col cols="11" dense>
                  <ol>
                    <li v-for="(upload, i) in uploads" :key="i">
                      <a :href="upload.href" target="_blank">
                        {{ upload.originalFilename }}
                      </a>
                    </li>
                  </ol>
                </v-col>
              </v-row>
              <v-row class="text-right" dense>
                <v-col cols="12" dense>
                  <v-btn class="primary" @click="uploadedFilesDialog = false">Close</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="preferredAwardVehicleDialog" max-width="800" persistent>
        <v-card>
          <v-card-title>Preferred Payout Method?</v-card-title>
          <v-card-text>
            <span v-if="participantSelectedToClaimOnBehalf && participantSelectedToClaimOnBehalf.id">
              <p>
                There are multiple ways in which {{ participantSelectedToClaimOnBehalf.fullName }} can earn from this
                promotion.
              </p>
              <p>
                Please have {{ participantSelectedToClaimOnBehalf.firstName }} update their preferred payout in within
                their profile page before submitting this claim.
              </p>
            </span>
            <span v-else
              ><p>
                There are multiple ways in which you can earn from this promotion.
              </p>
              <p>
                Please update your preferred payout method within your
                <router-link :to="{ name: 'profile' }">profile</router-link> page.
              </p></span
            >
          </v-card-text>
          <v-card-actions>
            <span v-if="!participantSelectedToClaimOnBehalf || !participantSelectedToClaimOnBehalf.id">
              <v-btn color="primary" :to="{ name: 'profile' }">Profile</v-btn>
            </span>
            <v-spacer></v-spacer>

            <v-btn color="primary" :to="{ name: 'dashboard' }">Back to Dashboard</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <ApiError :errors="errors"></ApiError>

      <ClaimForm :promotionId="promotionId" @onCancel="onCancel"></ClaimForm>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <!--<v-btn>{{ $i18n.translate("Save") }}</v-btn>-->
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ApiError from "../../gapp-components/components/display/ApiError.vue";
import ClaimForm from "../../gapp-components/components/forms/claim/ClaimForm.vue";

import moment from "moment-timezone";
import Vue from "vue";

export default {
  components: { ApiError, ClaimForm },
  name: "CprClaim",
  metaInfo: {
    title: "Claim"
  },
  data: () => ({
    testRender: true,
    step: "1",
    valid: {
      step1: false,
      step2: false,
      step3: false
    },
    isBusy: false,
    isEditing: true,
    promotionId: 0,
    errors: {},
    serialNumberRegex: "",

    promotion: {},

    claimFields: [],
    claimFieldsByRow: [],
    claimFieldValues: {},

    selectedPromotionProducts: [],
    claimProducts: [],
    claimProductHeaders: [
      {
        value: "promotionProduct.product.productKey",
        text: "Model",
        align: "center"
      },
      {
        value: "serialNumber",
        text: "",
        sortable: false
      },
      {
        value: "actions",
        text: "Actions",
        align: "center",
        sortable: false
      }
    ],

    claimSubmitted: false,

    claimStage: null,

    claimUploads: {
      existing: [],
      deleted: []
    },

    rules: {
      required: [v => !!v || "Field is required"],
      participant: [v => typeof v == "object" || "Participant is required"],
      quantity: [v => !!v || "Quantity is required"]
    },

    form: {},
    uploads: {},
    uploadedFilesDialog: false,

    participantSelectedToClaimOnBehalf: null,
    claimNumber: "",
    q1: false,
    zeroSales: false,
    zeroSalesItems: [],
    selectedZeroSalesPeriod: null,
    formattedZeroSalesPeriod: null,
    saveEndUserForLater: true,
    selectedEndUser: null,

    preferredAwardVehicle: undefined,
    availableAwardVehicles: [],
    preferredAwardVehicleDialog: false,

    participantSkipClaimDocumentation: false,
    promotionSkipClaimDocumentation: false,
    promotionTypeDocumentationRequired: false,
    searchInput: null
  }),
  created() {
    this.promotionId = parseInt(this.$route.params.id);
    this.participantSkipClaimDocumentation = this.selectedParticipant.skipClaimDocumentation;
    this.breadcrumb();
    this.fetchData();
    this.loadZeroSalesMonths();
  },
  watch: {
    claimUploads: {
      handler() {
        this.$refs.uploadForm.validate();
      },
      deep: true
    },
    selectedZeroSalesPeriod() {
      this.formattedZeroSalesPeriod = moment
        .tz(this.selectedZeroSalesPeriod, this.selectedClient.timezone)
        .format("MMMM YYYY");
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient"]),
    endUserRelated() {
      let result = false;
      for (let field of this.claimFields) {
        if (field.claimFieldType.name.startsWith("END_USER")) {
          result = true;
        }
      }
      return result;
    },
    documentationRequired() {
      return (
        !this.participantSkipClaimDocumentation &&
        !this.promotionSkipClaimDocumentation &&
        this.promotionTypeDocumentationRequired
      );
    },
    participantToClaim() {
      return this.participantSelectedToClaimOnBehalf && this.participantSelectedToClaimOnBehalf.id
        ? this.participantSelectedToClaimOnBehalf
        : this.selectedParticipant;
    },
    totalProductsClaimed() {
      let amount = 0;
      for (let cp of this.claimProducts) {
        if (cp.quantity) {
          amount += eval(cp.quantity);
        } else {
          amount += 1;
        }
      }
      return amount;
    },
    userHasPrivilegeToClaimOnHalfofAnyone() {
      return this.$privilege.hasPrivilege("CLAIM_ON_BEHALF_OF_ANYONE");
    }
  },
  methods: {
    updateSonyDealerFieldsVisibility(v) {
      let selectedItem = v.selectedItem;
      let other = v.other;
      for (let claimField of this.claimFields) {
        if (claimField.name && claimField.name.startsWith("Sony Dealer") && claimField.name.trim() != "Sony Dealer") {
          claimField.hidden = !v.other;
          if (!other) {
            if (selectedItem) {
              switch (claimField.name) {
                case "Sony Dealer Name":
                  this.claimFieldValues[claimField.id] = selectedItem.name;
                  break;
                case "Sony Dealer Address 1":
                  this.claimFieldValues[claimField.id] = selectedItem.address.address1;
                  break;
                case "Sony Dealer Address 2":
                  this.claimFieldValues[claimField.id] = selectedItem.address.address2;
                  break;
                case "Sony Dealer City":
                  this.claimFieldValues[claimField.id] = selectedItem.address.city;
                  break;
                case "Sony Dealer State / Province":
                  this.claimFieldValues[claimField.id] = selectedItem.address.region;
                  break;
                case "Sony Dealer Country":
                  this.claimFieldValues[claimField.id] = selectedItem.address.country;
                  break;
                case "Sony Dealer Postal Code":
                  this.claimFieldValues[claimField.id] = selectedItem.address.postalCode;
                  break;
              }
            }
          }
          if (other || !selectedItem) {
            this.claimFieldValues[claimField.id] = null;
          }
        }
      }
      this.$forceUpdate();
    },
    onNextStep() {
      this.step++;
    },
    checkDuplicatedSerialNumber(item) {
      let count = 0;
      this.claimProducts.forEach(element => {
        if (
          element.serialNumber &&
          element.promotionProduct.product.id == item.promotionProduct.product.id &&
          element.serialNumber == item.serialNumber
        ) {
          count++;
        }
      });
      if (count > 1) {
        return "Serial number duplicated";
      }
      return true;
    },
    requireSerialNumber(item) {
      if (item.promotionProduct.enableSerialNumbers) {
        if (item.serialNumber) {
          if (item.promotionProduct.product.serialNumberRegex) {
            let regex = RegExp("^" + item.promotionProduct.product.serialNumberRegex);
            if (regex.test(item.serialNumber)) {
              return true;
            } else {
              return "Invalid Serial Number";
            }
          } else {
            return true;
          }
        } else {
          return "Serial Number required";
        }
      } else {
        return false;
      }
    },
    requireProduct() {
      if (this.claimProducts.length > 0) {
        return true;
      } else {
        return "At least 1 product is required";
      }
    },
    requireUpload() {
      if (this.claimUploads.existing.length > 0) {
        return true;
      } else {
        return "At least 1 upload is required";
      }
    },
    onAddProduct() {
      let promotionProducts = this.claimProducts;
      this.selectedPromotionProducts.some(promotionProduct => {
        let found = promotionProducts.findIndex(
          needle => needle.promotionProduct.product.id == promotionProduct.product.id
        );
        if (found == -1) {
          this.$api.get("/api/promotionProducts/" + promotionProduct.id + "/allowPriceEntry").then(res => {
            promotionProduct.allowPriceEntry = res.data;
            promotionProducts = promotionProducts.concat([{ promotionProduct: promotionProduct }]);
            this.selectedPromotionProducts = [];
            this.$set(this, "claimProducts", promotionProducts);
          });
        } else {
          if (promotionProduct.enableSerialNumbers) {
            promotionProducts = promotionProducts.concat([{ promotionProduct: promotionProduct }]);
          }
        }
      });
      this.claimProducts = promotionProducts;
      this.selectedPromotionProducts = [];
    },
    onDeleteProduct(claimProduct) {
      const index = this.claimProducts.indexOf(claimProduct);
      this.claimProducts.splice(index, 1);
    },
    onDuplicateProduct(claimProduct) {
      let claimProductCopy = { ...claimProduct };
      this.claimProducts = this.claimProducts.concat(claimProductCopy);
    },
    onSubmit() {
      this.isBusy = true;
      this.errors = {};

      let postForm = {};
      postForm.promotionKey = this.promotion.promotionKey;
      postForm.browserSource = window.location.href;
      postForm.browserReferer = document.referrer || this.getReferrerFromQueryString();
      if (this.participantSelectedToClaimOnBehalf && this.participantSelectedToClaimOnBehalf.id) {
        postForm.participant = { id: this.participantSelectedToClaimOnBehalf.id };
      } else {
        postForm.participant = { id: this.selectedParticipant.id };
      }

      if (this.q1 == "yes") {
        postForm.zeroSales = true;
        postForm.zeroSalesDate = moment
          .tz(this.selectedZeroSalesPeriod, this.selectedClient.timezone)
          .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        postForm.uploads = [];
        postForm.claimProducts = [];
        postForm.claimFieldValues = [];
      } else {
        // postForm.claimStage - allow this to be determined
        postForm.zeroSales = false;
        postForm.uploads = this.claimUploads.existing;
        postForm.claimProducts = this.getClaimProducts();
        postForm.claimFieldValues = this.getClaimFieldValues();
      }

      postForm.emailTemplateParticipantForm = {
        emailTemplateKey: "CLAIM_SUBMISSION"
      };
      postForm.saveEndUserForLater = this.selectedEndUser ? false : this.saveEndUserForLater;
      if (this.$route.query.claimId == undefined) {
        this.$api
          .post("/api/claims/submit", postForm)
          .then(({ data }) => {
            this.form = data;
            this.claimNumber = data.claimKey;
            this.isBusy = false;
            this.claimSubmitted = true;
          })
          .catch(error => {
            this.errors = this.$api.getErrorsFromResponse(error);
            this.$vuetify.goTo(0);
            this.isBusy = false;
          });
      } else {
        postForm.submitAfterUpdate = true;
        this.$api
          .post("/api/claims/" + this.$route.query.claimId + "/update", postForm)
          .then(({ data }) => {
            this.form = data;
            this.claimNumber = data.claimKey;
            this.isBusy = false;
            this.claimSubmitted = true;
          })
          .catch(error => {
            this.errors = this.$api.getErrorsFromResponse(error);
            this.$vuetify.goTo(0);
            this.isBusy = false;
          });
      }
    },
    getReferrerFromQueryString() {
      var queryString = window.location.search.substring(1);
      var params = new URLSearchParams(queryString);
      if (params.has("referrer")) {
        return params.get("referrer");
      } else {
        return null;
      }
    },
    getClaimFieldValues() {
      const claimFieldsClone = this.claimFields.slice();
      let cfValues = [];
      claimFieldsClone.map(claimField => {
        let cfValue = {
          claimField: { id: claimField.id },
          value:
            this.claimFieldValues[claimField.id] && this.claimFieldValues[claimField.id].name
              ? this.claimFieldValues[claimField.id].name.trim()
              : this.claimFieldValues[claimField.id]
              ? this.claimFieldValues[claimField.id].trim()
              : this.claimFieldValues[claimField.id]
        };
        cfValues.push(cfValue);
      });

      return cfValues;
    },

    getClaimProducts() {
      let claimProducts = [];
      this.claimProducts.forEach(claimProduct => {
        let cp = Object.assign({}, claimProduct);
        cp.priceAmountCurrency = { name: this.promotion.country.currencyName };
        cp.promotionProduct = { id: claimProduct.promotionProduct.id };
        claimProducts.push(cp);
      });

      return claimProducts;
    },

    fetchPreferredAwardVehicle() {
      return this.$api
        .get("/api/participants/" + this.participantToClaim.id + "/preferredAwardVehicle")
        .then(({ data }) => {
          this.preferredAwardVehicle = data;
        })
        .catch(() => {
          this.preferredAwardVehicle = undefined;
        });
    },
    fetchAvailableAwardVehicles() {
      return this.$awardvehicle.getAvailableAwardVehicles(this.participantToClaim).then(avs => {
        this.availableAwardVehicles = avs;
      });
    },
    onChangeParticipantClaimOnBehalf() {
      Promise.all([this.fetchPreferredAwardVehicle(), this.fetchAvailableAwardVehicles()]);
    },
    fetchData() {
      this.isBusy = true;

      this.$api.get("/api/promotions/" + this.$route.params.id).then(({ data }) => {
        this.promotion = data;
        this.promotionSkipClaimDocumentation = this.promotion.skipClaimDocumentation;
        this.breadcrumb();

        Promise.all([
          this.fetchPreferredAwardVehicle(),
          this.fetchAvailableAwardVehicles(),

          this.$api.getRelatedObject("promotionType", data).then(({ data }) => {
            this.promotion.promotionType = data;
            if (this.promotion.promotionType.uploadLimit > 0) {
              this.promotionTypeDocumentationRequired = true;
            }
          }),

          this.$api.getRelatedObject("claimType", data).then(({ data }) => {
            this.promotion.claimType = data;
            // claim stage
            this.$api
              .get("/api/claimStages/byClaimTypeId/" + data.id + "?sort=rankOrder,ASC&size=1")
              .then(({ data }) => {
                this.claimStage = data.content[0];
              });

            // claim fields
            this.$api
              .get("/api/claimFields/byClaimTypeId/" + data.id + "?sort=rankOrder,ASC&size=200")
              .then(({ data }) => {
                this.claimFields = data.content;
                this.claimFieldsByRow = [];
                let rowCount = -1;
                let colCount = 0;
                this.claimFields.forEach((claimField, i) => {
                  if (!claimField.cols || claimField.cols < 1 || claimField.cols > 12) {
                    claimField.cols = 12;
                  }
                  if (i == 0 || this.claimFields[i].category != this.claimFields[i - 1].category) {
                    claimField.newCategory = true;
                  } else {
                    claimField.newCategory = false;
                  }
                  if (claimField.newCategory || colCount + claimField.cols > 12) {
                    rowCount++;
                    colCount = claimField.cols;
                  } else {
                    colCount += claimField.cols;
                  }
                  if (!this.claimFieldsByRow[rowCount]) {
                    this.claimFieldsByRow[rowCount] = [];
                  }
                  this.claimFieldsByRow[rowCount].push(claimField);

                  if (claimField.defaultValue && claimField.defaultValue.length > 0) {
                    Vue.set(this.claimFieldValues, claimField.id, claimField.defaultValue);
                  }
                });
              });
          }),

          this.$api
            .post("/api/promotionProducts/search?size=500&page=0&nestedSort=product.productKey,ASC", {
              promotion: { id: data.id },
              isEffective: true
            })
            .then(({ data }) => {
              this.promotion.promotionProducts = data.content.filter(item => item.effective == true);
            }),

          this.$api.post("/api/promotionDeadlines/search", { promotion: { id: data.id } }).then(({ data }) => {
            this.promotion.promotionDeadlines = data;
          }),

          this.fetchClaimData()
        ])
          .then(() => {
            if (!this.zeroSales && !this.preferredAwardVehicle && this.availableAwardVehicles.length > 1) {
              this.preferredAwardVehicleDialog = true;
            }
            this.showPromotion = true;
          })
          .finally(() => {
            this.isBusy = false;
          });
      });
    },
    fetchClaimData() {
      if (this.$route.query.claimId != undefined && this.$route.query.claimId != 0) {
        return this.$api.get("/api/claims/" + this.$route.query.claimId).then(({ data }) => {
          Promise.all([
            this.$api.get("/api/claimFieldValues/byClaimId/" + data.id + "?size=100").then(({ data }) => {
              data.content.forEach(claimFieldValue => {
                Vue.set(this.claimFieldValues, claimFieldValue.claimFieldId, claimFieldValue.value);
              });
            }),
            this.$api.get("/api/claimProducts/byClaimId/" + data.id + "?size=500&page=0").then(({ data }) => {
              this.claimProducts = data.content;
            }),
            this.$api.getRelatedObject("uploads", data).then(({ data }) => {
              this.claimUploads.existing = data._embedded.uploads;
            }),
            this.$api.getRelatedObject("participant", data).then(({ data }) => {
              this.participantSelectedToClaimOnBehalf = data;
            })
          ]).finally(() => {
            this.$forceUpdate();

            this.$refs.step1Form.validate();
            this.$refs.step2Form.validate();
          });
        });
      } else {
        return Promise.resolve();
      }
    },
    breadcrumb() {
      if (this.promotion.name) {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: "Eligible Promotions",
            to: { name: "cprClaims" },
            exact: true
          },
          { text: "Claim - " + this.promotion.name + " (" + this.promotion.promotionKey + ")" }
        ]);
      } else {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: "Eligible Promotions",
            to: { name: "cprClaims" },
            exact: true
          },
          { text: "Claim" }
        ]);
      }
    },
    onCancel() {
      if (this.$route.query.claimId != undefined && this.$route.query.claimId != 0) {
        this.$router.push({ name: "cprMyClaims", query: { tab: 2 } });
      } else {
        this.$router.push({ name: "cprClaims" });
      }
    },
    showUploadedFilesDialog() {
      this.$api
        .get("/api/promotions/" + this.$route.params.id + "/uploads/")
        .then(({ data }) => {
          this.uploads = data._embedded.uploads;
        })
        .then(() => {
          this.uploadedFilesDialog = true;
        });
    },
    formatSerialNumber(item) {
      if (item.serialNumber) {
        if (item.serialNumber.startsWith("S01")) {
          item.serialNumber = item.serialNumber.substring(3);
        }
        if (item.serialNumber.length < 7) {
          item.serialNumber = item.serialNumber.padStart(7, "0");
        }
      }
    },
    onClear() {
      this.participantSelectedToClaimOnBehalf = null;
    },
    backToDashboard() {
      this.$router.push({ name: "dashboard" });
    },
    loadFields(v) {
      this.selectedEndUser = v;
      if (v != undefined) {
        for (let claimField of this.claimFields) {
          let claimFieldId = claimField.id;
          switch (claimField.claimFieldType.name) {
            case "END_USER_POSTAL_CODE_TYPE":
              Vue.set(this.claimFieldValues, claimFieldId, v.address.postalCode);
              break;
            case "END_USER_FIRST_NAME_TYPE":
              Vue.set(this.claimFieldValues, claimFieldId, v.firstName);
              break;
            case "END_USER_LAST_NAME_TYPE":
              Vue.set(this.claimFieldValues, claimFieldId, v.lastName);
              break;
            case "END_USER_ORGANIZATION_NAME_TYPE":
              Vue.set(this.claimFieldValues, claimFieldId, v.organizationName);
              break;
            case "END_USER_ADDRESS1_TYPE":
              Vue.set(this.claimFieldValues, claimFieldId, v.address.address1);
              break;
            case "END_USER_ADDRESS2_TYPE":
              Vue.set(this.claimFieldValues, claimFieldId, v.address.address2);
              break;
            case "END_USER_CITY_TYPE":
              Vue.set(this.claimFieldValues, claimFieldId, v.address.city);
              break;
            case "END_USER_REGION_TYPE":
              Vue.set(this.claimFieldValues, claimFieldId, v.address.region);
              break;
            case "END_USER_COUNTRY_TYPE":
              Vue.set(this.claimFieldValues, claimFieldId, v.address.country);
              break;
            case "END_USER_EMAIL_TYPE":
              Vue.set(this.claimFieldValues, claimFieldId, v.email);
              break;
            case "END_USER_PHONE_NUMBER1_TYPE":
              Vue.set(this.claimFieldValues, claimFieldId, v.phoneNumber1);
              break;
            case "END_USER_PHONE_NUMBER2_TYPE":
              Vue.set(this.claimFieldValues, claimFieldId, v.phoneNumber2);
              break;
            case "END_USER_INDUSTRY":
              Vue.set(this.claimFieldValues, claimFieldId, v.industry);
              break;
          }
        }
        this.$forceUpdate();
      }
    },
    onEndUserDelete(v) {
      if (v.id == this.selectedEndUser.id) {
        this.selectedEndUser = null;
      }
    },
    keyHandler(v) {
      if (v.key == " " || v.key == ",") {
        event.preventDefault();
      }
    },
    loadZeroSalesMonths() {
      this.zeroSalesItems = [];
      let month = moment();
      month.subtract(1, "months");
      for (let i = 0; i < 3; i++) {
        this.zeroSalesItems.push({
          text: month.format("MMMM YYYY"),
          value: month.format("YYYY-MM-[01]")
        });
        month.add(1, "months");
      }
    }
  }
};
</script>
